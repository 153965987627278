import Vue from "vue";
import App from "./App.vue";
import vueSmoothScroll from "vue-smooth-scroll";
import AOS from "aos";
import router from "./router";
import "aos/dist/aos.css";

AOS.init();
Vue.config.productionTip = false;

Vue.use(vueSmoothScroll);

new Vue({
	router,
	render: h => h(App)
}).$mount("#app");
