function load(componentName) {
	return () => import(`@/components/${componentName}`);
}

const routes = [
	{
		path: "/",
		name: "Home",
		component: load("Home"),
		meta: { title: "Yishi Zhao - Viola" }
	},
	{
		path: "/media",
		name: "Media",
		component: load("MediaPage"),
		meta: { title: "Yishi Zhao — Media" }
	}
];

export default routes;
