<template>
	<div id="app">
			<router-view/>
</div>
</template>

<script>
export default {
	name: "App",
		watch: {
				'$route' (to) {
						document.title = to.meta.title 				}
		}
};
</script>

<style>
@media (max-width: 550px) {
	html {
		scroll-snap-type: y proximity;
	}
	h2 {
		font-size: 1.5rem;
		font-weight: 400;
		text-align: center;
	}
	p {
		font-weight: 200;
	}
}
@media (min-width: 550px) {
	img {
		width: 100%;
		display: block;
		height: auto;
	}

	p {
		padding: 1vh 0;
	}
	h2 {
		text-align: center;
		font-weight: 400;
		font-size: 3vw;
	}
}
* {
	box-sizing: border-box;
}
body {
	padding: 0;
	margin: 0;
	font-family: "Montserrat", sans-serif;
	overflow-x: hidden;
}

</style>
